import React from 'react';

function Hamburg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        d="M17.48 58.21s22.09 20.99 49.15-23.2c0 0 47.5 67.93 76.21 31.48 0 0 38.11 82.29 80.08 33.69 0 0 31.48 39.76 57.99 24.85v155.19H15.82l1.66-222.01z"
        fill="none"
        stroke="#4c6c21"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M66.79 37.77v243m-50.81-73.45l49.38-2.21 216.73 9.94m-266.11-.16l49.38-2.2 216.73 9.94M96.61 278.56v-64.61m56.05 66.51v-64.62m52.26 62.22v-58.81m51.51 60.88v-58.81"
        fill="none"
        stroke="#4c6c21"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M28.26 78.64h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.22-2.94-4.24-7.49zm14.56 9.93h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.22-2.94-4.24-7.49zm-4.63 23.5h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.04-4.23-2.95-4.24-7.49zm0 24.16h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.04-4.23-2.95-4.24-7.49zm9.92 10.92h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.74.04-4.22-2.95-4.24-7.49zm-20.51 22.5h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.23-2.95-4.24-7.49zm0-22.17h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.04-4.23-2.95-4.24-7.49zm2.98-21.51h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.04-4.23-2.95-4.24-7.49zm16.87 56.59h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.74.04-4.22-2.95-4.24-7.49zm33.1-74.79h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.04-4.23-2.95-4.24-7.49zm26.82-6.68h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.22-2.95-4.24-7.49zM82.84 79.94h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.23-2.94-4.24-7.49zm4.23 47.8h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.23-2.95-4.24-7.49zm36.8 3.81h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.04-4.23-2.95-4.24-7.49zm-7.2 24.11h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.74.04-4.22-2.95-4.24-7.49zm35.11 12.69h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.74.04-4.22-2.95-4.24-7.49zm26.23-18.62h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.23-2.94-4.24-7.49zM211 133.66h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.75.05-4.22-2.95-4.24-7.49zm38.49 33.42h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.74.04-4.22-2.95-4.24-7.49zm0 26.64h8.15c.09 4.42-2.19 7.45-3.91 7.49-1.74.05-4.22-2.94-4.24-7.49z"
        fill="#4c6c21"
      />
    </svg>
  );
}

export default Hamburg;
