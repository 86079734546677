import React from 'react';

function Cologne(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="#fff"
        stroke="#5e7d2b"
        strokeWidth="5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M77.41 11.74L61.54 88.57 51.52 76.88l-12.53 97.7-1.67 39.25 79.34-4.17-1.68-35.91-12.52-96.87-10.02 15.03z"
      />
      <path
        fill="#fff"
        d="M63.92 186.89v-46.04c.26-5.82 2-11.41 5.05-16.1 1.86-2.86 5.31-8.19 8.57-8.19 4.01 0 9.6 8.39 12.52 24.42v45.91H63.92z"
      />
      <path
        fill="#5e7d2b"
        d="M77.53 118c2.76 0 8.18 7.35 11.07 23.11v44.33H65.37v-44.53c.26-5.6 1.92-10.92 4.81-15.38 1.46-2.25 4.89-7.53 7.35-7.53m0-2.89c-4.04 0-7.62 5.52-9.78 8.85-4.32 6.66-5.12 13.33-5.28 16.89v47.49H91.5v-47.49c-2.78-15.35-8.43-25.74-13.97-25.74z"
      />
      <path
        fill="#fff"
        d="M51.12 204.58v-31.85c.18-3.99 1.38-7.83 3.47-11.05 1.17-1.8 3.6-5.54 5.64-5.54 2.57 0 6.36 5.86 8.35 16.73v31.72H51.12z"
      />
      <path
        fill="#5e7d2b"
        d="M60.23 157.58c1.2 0 4.87 4.44 6.9 15.41v30.14H52.57V172.8c.18-3.77 1.29-7.34 3.24-10.33 2.75-4.25 3.95-4.89 4.42-4.89m0-2.89c-2.83 0-5.34 3.87-6.86 6.2-3.03 4.67-3.59 9.35-3.7 11.84v33.29h20.35v-33.29c-1.94-10.75-5.91-18.04-9.79-18.04z"
      />
      <path
        fill="#fff"
        d="M68.26 204.58v-31.85c.18-3.99 1.38-7.83 3.47-11.05 1.17-1.8 3.6-5.54 5.64-5.54 2.57 0 6.36 5.86 8.35 16.73v31.72H68.26z"
      />
      <path
        fill="#5e7d2b"
        d="M77.37 157.58c1.2 0 4.87 4.44 6.9 15.41v30.14H69.71V172.8c.18-3.77 1.29-7.34 3.24-10.33 2.75-4.25 3.95-4.89 4.42-4.89m0-2.89c-2.83 0-5.34 3.87-6.86 6.2-3.03 4.67-3.59 9.35-3.7 11.84v33.29h20.35v-33.29c-1.94-10.75-5.91-18.04-9.79-18.04z"
      />
      <path
        fill="#fff"
        d="M85.4 204.58v-31.85c.18-3.99 1.38-7.83 3.47-11.05 1.17-1.8 3.6-5.54 5.64-5.54 2.57 0 6.36 5.86 8.35 16.73v31.72H85.4z"
      />
      <path
        fill="#5e7d2b"
        d="M94.51 157.58c1.2 0 4.87 4.44 6.9 15.41v30.14H86.85V172.8c.18-3.77 1.29-7.34 3.24-10.33 2.75-4.25 3.95-4.89 4.42-4.89m0-2.89c-2.83 0-5.34 3.87-6.86 6.2-3.03 4.67-3.59 9.35-3.7 11.84v33.29h20.35v-33.29c-1.94-10.75-5.91-18.04-9.79-18.04z"
      />
      <path
        fill="#fff"
        stroke="#5e7d2b"
        strokeWidth="5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M222.49 14.39l-15.86 76.83-10.03-11.69-12.52 97.7-1.67 39.25 79.33-4.17-1.67-35.91-12.53-96.87-10.02 15.03z"
      />
      <g>
        <path
          fill="#fff"
          d="M209.01 189.54V143.5c.26-5.82 2-11.41 5.05-16.1 1.86-2.86 5.31-8.19 8.57-8.19 4.01 0 9.6 8.39 12.52 24.42v45.91h-26.14z"
        />
        <path
          fill="#5e7d2b"
          d="M222.62 120.65c2.76 0 8.18 7.35 11.07 23.11v44.33h-23.24v-44.53c.26-5.6 1.92-10.92 4.81-15.38 1.47-2.25 4.89-7.53 7.36-7.53m0-2.89c-4.04 0-7.62 5.52-9.78 8.85-4.32 6.66-5.12 13.33-5.28 16.89v47.49h29.03V143.5c-2.78-15.35-8.43-25.74-13.97-25.74z"
        />
        <g>
          <path
            fill="#fff"
            d="M196.21 207.23v-31.85c.18-3.99 1.38-7.83 3.47-11.05 1.17-1.8 3.6-5.54 5.64-5.54 2.57 0 6.36 5.86 8.35 16.73v31.72h-17.46z"
          />
          <path
            fill="#5e7d2b"
            d="M205.32 160.23c1.2 0 4.87 4.44 6.9 15.41v30.14h-14.56v-30.33c.18-3.77 1.29-7.34 3.24-10.33 2.75-4.25 3.95-4.89 4.42-4.89m0-2.89c-2.83 0-5.34 3.87-6.86 6.2-3.03 4.67-3.59 9.35-3.7 11.84v33.29h20.35v-33.29c-1.95-10.75-5.91-18.04-9.79-18.04z"
          />
          <path
            fill="#fff"
            d="M213.35 207.23v-31.85c.18-3.99 1.38-7.83 3.47-11.05 1.17-1.8 3.6-5.54 5.64-5.54 2.57 0 6.36 5.86 8.35 16.73v31.72h-17.46z"
          />
          <path
            fill="#5e7d2b"
            d="M222.46 160.23c1.2 0 4.87 4.44 6.9 15.41v30.14H214.8v-30.33c.18-3.77 1.29-7.34 3.24-10.33 2.75-4.25 3.95-4.89 4.42-4.89m0-2.89c-2.83 0-5.34 3.87-6.86 6.2-3.03 4.67-3.59 9.35-3.7 11.84v33.29h20.35v-33.29c-1.95-10.75-5.91-18.04-9.79-18.04z"
          />
          <g>
            <path
              fill="#fff"
              d="M230.49 207.23v-31.85c.18-3.99 1.38-7.83 3.47-11.05 1.17-1.8 3.6-5.54 5.64-5.54 2.57 0 6.36 5.86 8.35 16.73v31.72h-17.46z"
            />
            <path
              fill="#5e7d2b"
              d="M239.6 160.23c1.2 0 4.87 4.44 6.9 15.41v30.14h-14.56v-30.33c.18-3.77 1.29-7.34 3.24-10.33 2.75-4.25 3.95-4.89 4.42-4.89m0-2.89c-2.83 0-5.34 3.87-6.86 6.2-3.03 4.67-3.59 9.35-3.7 11.84v33.29h20.35v-33.29c-1.95-10.75-5.91-18.04-9.79-18.04z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#fff"
          d="M31.66 284.21V141.67c2.49-21.65 6.18-28.98 7.48-28.99 1.31 0 5.17 7.31 8.12 29v142.53h-15.6z"
        />
        <path
          fill="#5e7d2b"
          d="M39.17 114.71c1.67 2.56 4.47 11.06 6.65 27.07v140.98H33.11V141.75c1.84-15.95 4.45-24.45 6.06-27.04m-.03-3.48h-.02c-3.52.04-6.91 12.92-8.91 30.35v144.07h18.5V141.59c-2.37-17.51-6.04-30.36-9.57-30.36z"
        />
        <path
          fill="#fff"
          d="M49.7 284.21V207.5l-.01-.07c-.25-2.52.55-4.93 2.24-6.8 1.54-1.7 3.61-2.68 5.66-2.68.1 0 .21 0 .31.01 1.96.1 3.9 1.1 5.32 2.77 1.6 1.86 2.33 4.24 2.07 6.7l-.01.08v76.71H49.7z"
        />
        <path
          fill="#5e7d2b"
          d="M57.6 199.4c.08 0 .16 0 .24.01 1.56.08 3.13.9 4.29 2.26a7.36 7.36 0 011.73 5.61l-.02.15v75.34H51.15v-75.33l-.01-.14a7.28 7.28 0 011.88-5.69c1.26-1.41 2.93-2.21 4.58-2.21m0-2.89c-5.11 0-9.95 4.95-9.35 11.07v78.08h18.5v-78.08c.62-5.89-3.81-10.82-8.76-11.06-.13-.01-.26-.01-.39-.01z"
        />
        <path
          fill="#fff"
          d="M68.56 284.21v-94.88c-.09-9.81 4.8-15.91 7.5-15.97 2.8 0 7.97 6.07 8.11 15.99v94.86H68.56z"
        />
        <path
          fill="#5e7d2b"
          d="M76.1 174.81c1.65 0 6.49 5.01 6.62 14.52v93.44H70.01v-93.46c-.08-9.43 4.48-14.47 6.09-14.5m0-2.89h-.07c-3.87.08-9 7.12-8.91 17.41v96.33h18.5v-96.33c-.15-10.48-5.62-17.41-9.52-17.41z"
        />
        <g>
          <path
            fill="#fff"
            d="M86.6 284.21V207.5l-.01-.07c-.25-2.52.55-4.93 2.24-6.8 1.54-1.7 3.61-2.68 5.66-2.68.1 0 .21 0 .31.01 1.96.1 3.9 1.1 5.32 2.77 1.6 1.86 2.33 4.24 2.07 6.7l-.01.08v76.71H86.6z"
          />
          <path
            fill="#5e7d2b"
            d="M94.5 199.4c.08 0 .16 0 .24.01 1.56.08 3.13.9 4.29 2.26a7.36 7.36 0 011.73 5.61l-.02.15v75.34H88.05v-75.33l-.01-.14a7.28 7.28 0 011.88-5.69c1.26-1.41 2.93-2.21 4.58-2.21m0-2.89c-5.11 0-9.95 4.95-9.35 11.07v78.08h18.5v-78.08c.62-5.89-3.81-10.82-8.76-11.06-.13-.01-.26-.01-.39-.01z"
          />
        </g>
        <g>
          <path
            fill="#fff"
            d="M105.46 284.21v-94.88c-.09-9.81 4.8-15.91 7.5-15.97 2.8 0 7.97 6.07 8.11 15.99v94.86h-15.61z"
          />
          <path
            fill="#5e7d2b"
            d="M113 174.81c1.65 0 6.49 5.01 6.62 14.52v93.44h-12.71v-93.46c-.08-9.43 4.49-14.47 6.09-14.5m0-2.89h-.07c-3.87.08-9 7.12-8.91 17.41v96.33h18.5v-96.33c-.15-10.48-5.62-17.41-9.52-17.41z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#fff"
          d="M251.87 284.21V141.69c2.95-21.7 6.81-29 8.12-29 1.3.01 4.99 7.34 7.48 28.99v142.54h-15.6z"
        />
        <path
          fill="#5e7d2b"
          d="M259.96 114.71c1.6 2.59 4.21 11.1 6.06 27.04v141.01h-12.71V141.78c2.19-16.01 4.99-24.51 6.65-27.07m.03-3.48c-3.52 0-7.19 12.85-9.57 30.35v144.07h18.5V141.58c-2-17.43-5.39-30.32-8.91-30.35h-.02z"
        />
        <path
          fill="#fff"
          d="M233.83 284.21V207.5l-.01-.08c-.26-2.46.48-4.84 2.07-6.7 1.42-1.66 3.36-2.67 5.32-2.77.11-.01.21-.01.31-.01 2.06 0 4.12.98 5.66 2.68a8.757 8.757 0 012.24 6.81l-.01.07v76.71h-15.58z"
        />
        <path
          fill="#5e7d2b"
          d="M241.53 199.4c1.65 0 3.32.8 4.59 2.2 1.42 1.56 2.08 3.58 1.88 5.69l-.01.14v75.33h-12.71v-75.34l-.02-.15a7.36 7.36 0 011.73-5.61c1.17-1.36 2.73-2.19 4.29-2.26h.25m0-2.89c-.13 0-.26 0-.39.01-4.95.24-9.38 5.17-8.76 11.06v78.08h18.5v-78.08c.6-6.12-4.24-11.07-9.35-11.07z"
        />
        <path
          fill="#fff"
          d="M214.97 284.21v-94.88c.14-9.9 5.3-15.97 8.07-15.97 2.73.06 7.62 6.16 7.54 15.96v94.9h-15.61z"
        />
        <path
          fill="#5e7d2b"
          d="M223.03 171.92l.01 2.89c1.6.03 6.16 5.07 6.08 14.52v93.44h-12.71v-93.4c.13-9.55 4.97-14.56 6.62-14.56v-2.89m0 0c-3.9 0-9.37 6.93-9.51 17.42v96.33h18.5v-96.33c.09-10.3-5.05-17.33-8.91-17.41-.03-.01-.05-.01-.08-.01z"
        />
        <g>
          <path
            fill="#fff"
            d="M196.92 284.21V207.5l-.01-.08c-.26-2.46.48-4.84 2.07-6.7 1.42-1.66 3.36-2.67 5.32-2.77.11-.01.21-.01.31-.01 2.06 0 4.12.98 5.66 2.68a8.747 8.747 0 012.24 6.8l-.01.07v76.71h-15.58z"
          />
          <path
            fill="#5e7d2b"
            d="M204.63 199.4c1.65 0 3.32.8 4.59 2.2 1.42 1.56 2.08 3.58 1.88 5.69l-.01.14v75.33h-12.71v-75.34l-.02-.15a7.36 7.36 0 011.73-5.61c1.17-1.36 2.73-2.18 4.29-2.26h.25m0-2.89c-.13 0-.26 0-.39.01-4.95.24-9.38 5.17-8.76 11.06v78.08h18.5v-78.08c.6-6.12-4.25-11.07-9.35-11.07z"
          />
        </g>
        <g>
          <path
            fill="#fff"
            d="M178.06 284.21v-94.88c.14-9.9 5.3-15.97 8.07-15.97 2.73.06 7.62 6.16 7.53 15.96v94.9h-15.6z"
          />
          <path
            fill="#5e7d2b"
            d="M186.13 171.92l.01 2.89c1.6.03 6.16 5.07 6.08 14.52v93.44h-12.71v-93.4c.13-9.55 4.97-14.56 6.62-14.56v-2.89m0 0c-3.9 0-9.37 6.93-9.51 17.42v96.33h18.5v-96.33c.09-10.3-5.05-17.33-8.91-17.41-.03-.01-.06-.01-.08-.01z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#fff"
          d="M124.66 282.77v-93.44c.58-31.21 15.41-51.95 24.97-51.95 9.55.02 24.31 20.76 24.82 52v93.39h-49.79z"
        />
        <path
          fill="#5e7d2b"
          d="M149.63 140.27c2.92.01 8.21 3.82 12.92 12.19 5.57 9.87 8.76 22.98 9 36.92v90.49h-44v-90.49c.27-13.96 3.5-27.08 9.09-36.94 4.75-8.36 10.06-12.17 12.99-12.17m-.01-5.79c-11.5 0-27.25 22-27.86 54.85v96.33h55.58v-96.33c-.54-32.8-16.21-54.83-27.7-54.85h-.02z"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M124.66 283.1v-80.72c.27-12.36 3.6-24.07 9.38-32.91 4.76-7.28 10.73-11.8 15.59-11.8 9.55.01 24.31 17.88 24.82 44.77v80.67h-49.79z"
        />
        <path
          fill="#5e7d2b"
          d="M149.63 160.56c7.44.01 21.41 15.92 21.92 41.88v77.77h-44v-77.76c.58-25.99 14.62-41.89 22.08-41.89m-.01-5.79c-11.5 0-27.25 19.09-27.86 47.61v83.61h55.58v-83.61c-.54-28.47-16.21-47.59-27.7-47.61h-.02z"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M124.66 283.1v-69.71c.58-23.44 15.18-38.44 24.97-38.44 9.78.01 24.31 15.02 24.82 38.51v69.65h-49.79z"
        />
        <path
          fill="#5e7d2b"
          d="M149.63 177.84c3.02 0 8.32 2.39 13.33 9.08 5.31 7.09 8.36 16.51 8.59 26.53v66.75h-44v-66.74c.26-10.03 3.34-19.45 8.68-26.55 5.05-6.69 10.37-9.07 13.4-9.07m-.01-5.79c-11.5 0-27.25 16.58-27.86 41.34v72.6h55.58v-72.6c-.54-24.72-16.21-41.32-27.7-41.34h-.02z"
        />
      </g>
      <path
        fill="#fff"
        stroke="#5e7d2b"
        strokeWidth="3"
        strokeMiterlimit="10"
        d="M139.21 220.62h22.54v61.61h-22.54z"
      />
      <path fill="#5e7d2b" d="M30.38 279.48h238.54v8.38H30.38z" />
    </svg>
  );
}

export default Cologne;
