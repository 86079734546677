import React from 'react';
import { Box } from '@heycater/design-system';

import { Caterer } from 'lib/algolia/model';
import yup from 'lib/yup';
import {
  createSelectorQuestion,
  createStep,
} from 'qualification/schema/questions/utils';
import Berlin from 'shared/svg/cities/Berlin';
import Cologne from 'shared/svg/cities/Cologne';
import Dusseldorf from 'shared/svg/cities/Dusseldorf';
import FrankfurtMain from 'shared/svg/cities/FrankfurtMain';
import Hamburg from 'shared/svg/cities/Hamburg';
import Munich from 'shared/svg/cities/Munich';
import Other from 'shared/svg/cities/Other';
import Stuttgart from 'shared/svg/cities/Stuttgart';

const CITY_VALUES = [
  'muenchen',
  'berlin',
  'frankfurt-am-main',
  'hamburg',
  'duesseldorf',
  'koeln',
  'stuttgart',
  'other',
] as const;

export const CITY_QUESTION_ITEMS = [
  {
    labelEn: 'Munich',
    labelDe: 'München',
    value: 'muenchen',
    imageComponent: Munich,
  },
  {
    labelEn: 'Berlin',
    labelDe: 'Berlin',
    value: 'berlin',
    imageComponent: Berlin,
  },
  {
    labelEn: 'Frankfurt am Main',
    labelDe: 'Frankfurt am Main',
    value: 'frankfurt-am-main',
    imageComponent: FrankfurtMain,
  },
  {
    labelEn: 'Hamburg',
    labelDe: 'Hamburg',
    value: 'hamburg',
    imageComponent: Hamburg,
  },
  {
    labelEn: 'Dusseldorf',
    labelDe: 'Düsseldorf',
    value: 'duesseldorf',
    imageComponent: Dusseldorf,
  },
  {
    labelEn: 'Cologne',
    labelDe: 'Köln',
    value: 'koeln',
    imageComponent: Cologne,
  },
  {
    labelEn: 'Stuttgart',
    labelDe: 'Stuttgart',
    value: 'stuttgart',
    imageComponent: Stuttgart,
  },
  {
    value: 'other',
    labelEn: 'Other City',
    labelDe: 'Sonstige Stadt',
    imageComponent: () => (
      <Box
        height="80px"
        width="80px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Other width="24px" />
      </Box>
    ),
  },
] as const;

export type CityValue = typeof CITY_VALUES[number];

export const cityQuestion = Object.freeze(
  createSelectorQuestion({
    id: 'city',

    getValidator: ({ selectedCaterer }) => {
      const supportedCityValues = getSupportedCityValues(selectedCaterer);
      return yup.mixed<CityValue>().oneOf(supportedCityValues).required();
    },
    getItems: ({ selectedCaterer }) => {
      const supportedCityValues = getSupportedCityValues(selectedCaterer);
      return CITY_QUESTION_ITEMS.filter(({ value }) =>
        supportedCityValues.includes(value)
      );
    },
  })
);

export const cityStep = Object.freeze(
  createStep({
    id: 'city',
    questions: [cityQuestion],
    content: {
      titleEn: 'Select your City',
      titleDe: 'Wähle deine Stadt',
    },
  })
);

function getSupportedCityValues(caterer?: Caterer) {
  let supportedCityValues: CityValue[] = [...CITY_VALUES];

  if (caterer) {
    supportedCityValues = [
      ...CITY_VALUES.filter((city) => caterer.city_slugs.includes(city)),
    ];
    const catererSupportsNonDefaultCities = Boolean(
      caterer &&
        caterer.city_slugs.some(
          (city) => !CITY_VALUES.includes(city as CityValue)
        )
    );
    if (catererSupportsNonDefaultCities) {
      supportedCityValues.push('other');
    }
  }
  return supportedCityValues;
}
export type CityQuestion = typeof cityQuestion;
export type CityStep = typeof cityStep;

export function isValidCity(value: unknown): value is CityValue {
  return CITY_VALUES.includes(value as CityValue);
}
