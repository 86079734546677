let localStorageExistence = undefined;

const WRITE_TEST_KEY = '_heycater_write_test';

export function doesLocalStorageExist() {
  if (typeof localStorageExistence === 'undefined') {
    try {
      localStorage.setItem(WRITE_TEST_KEY, '1');
      localStorage.removeItem(WRITE_TEST_KEY);
      localStorageExistence = true;
    } catch (_err) {
      localStorageExistence = false;
    }
  }

  return localStorageExistence;
}
