import React from 'react';

function Stuttgart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="#5e7d2b"
        d="M153.67 89.31h-7.59V2.44h7.59v86.87zm-5.25-2.34h2.91V4.78h-2.91v82.19z"
      />
      <path
        fill="#5e7d2b"
        d="M147.25 13.52h5.15v1.17h-5.15zM147.51 25.74h5.15v1.17h-5.15zM147.6 37.96h5.15v1.17h-5.15zM123.56 85.3h52.88v3.22h-52.88z"
      />
      <path
        fill="#5e7d2b"
        stroke="#5e7d2b"
        strokeMiterlimit="10"
        d="M183.02 109.44h-66.04V87.39h66.04v22.05zm-63.7-2.33h61.36V89.73h-61.36v17.38z"
      />
      <path
        fill="#5e7d2b"
        d="M163.05 116.55l-27.05-.16-16.45-7.46h61l-17.5 7.62zm-26.68-1.27h.08l26.35.1 12.13-5.28h-49.98l11.42 5.18z"
      />
      <path
        fill="#5e7d2b"
        d="M166.71 123.33H133.3v-7.94h33.41v7.94zm-32.25-1.17h31.08v-5.6h-31.08v5.6z"
      />
      <g>
        <path
          fill="#5e7d2b"
          d="M160.36 131.97h-20.72v-9.11h20.72v9.11zm-18.38-2.34h16.04v-4.43h-16.04v4.43z"
        />
      </g>
      <g>
        <path
          fill="#5e7d2b"
          stroke="#5e7d2b"
          strokeMiterlimit="10"
          d="M169.09 297.56h-37.02l9.47-167.86 17.64-1.04 9.91 168.9zm-33.32-3.5h29.6l-9.49-161.69-11.03.65-9.08 161.04z"
        />
      </g>
      <g>
        <path fill="#5e7d2b" d="M140.65 127.21h18.7v7.31h-18.7z" />
      </g>
      <g>
        <path fill="#5e7d2b" d="M149.42 149.83h1.17v21.56h-1.17z" />
      </g>
      <g>
        <path fill="#5e7d2b" d="M149.42 181.41h1.17v21.56h-1.17z" />
      </g>
      <g>
        <path fill="#5e7d2b" d="M149.42 212.99h1.17v21.56h-1.17z" />
      </g>
    </svg>
  );
}

export default Stuttgart;
