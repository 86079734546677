import { parse } from 'qs';

import { getFallbackForLocale, localeResources } from 'i18n';
import { LOCALES } from 'shared/constants';
import { doesLocalStorageExist } from 'shared/helpers/localStorageExists';

export const cityMappingByLocale = (locale = 'de') => {
  const parsedLocale = getFallbackForLocale(locale || 'de');
  const translation = localeResources[parsedLocale]?.common;
  return {
    ...translation.cities,
    ...translation.citiesUK,
    ...translation.citiesAustria,
    ...translation.citiesSwitzerland,
    ...translation.citiesDenmark,
  };
};

export const cityFromUrlSlug = ({ slug, locale }) => {
  const URLParts = slug.split('-');
  const citiesMapping = cityMappingByLocale(locale);
  const foundEntry = Object.entries(citiesMapping).find(([cityKey]) =>
    URLParts.includes(cityKey.split('-')[0])
  );
  if (!foundEntry) {
    return null;
  }
  const [cityKey, localizedCity] = foundEntry;
  return {
    cityKey,
    localizedCity,
  };
};

export const localizedCityFromURLSlug = ({ slug, locale = 'de' }) => {
  return cityFromUrlSlug({ slug, locale })?.localizedCity || null;
};

export const cityKeyFromURLSlug = ({ slug, locale }) => {
  return cityFromUrlSlug({ slug, locale })?.cityKey || null;
};

export const getCityFromURLParams = () => {
  if (typeof window === 'undefined') return null;

  const { city } = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return Object.keys(cityMappingByLocale()).includes(city) ? city : null;
};

const PERSISTED_CITY_KEY = '_heycater_city';

export const persistCityInLocalStorage = (city) => {
  if (!city) return;
  if (doesLocalStorageExist()) {
    localStorage.setItem(PERSISTED_CITY_KEY, city);
  }
};

export const getValueInLocalStorage = (key) => {
  if (doesLocalStorageExist()) {
    return localStorage.getItem(`_heycater_${key}`);
  }
};

export const getCityInLocalStorage = () => {
  if (doesLocalStorageExist()) {
    return localStorage.getItem(PERSISTED_CITY_KEY);
  }
};

export const findCitySlug = (cityName) => {
  if (!cityName) return null;

  const cityNameLower = cityName.toLowerCase();
  for (let locale of LOCALES) {
    const cities = cityMappingByLocale(locale);
    if (cities[cityNameLower]) {
      return cityNameLower;
    }
    const citySlug = Object.keys(cities).find(
      (key) => cities[key]?.toLowerCase() === cityNameLower
    );
    if (citySlug) return citySlug;
  }

  return null;
};

export const findCityNameByLocale = (key, locale = 'en') => {
  const cities = cityMappingByLocale(locale);
  return cities[key];
};
