import React from 'react';

function Other(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="41"
      viewBox="0 0 32 41"
      fill="none"
      {...props}
    >
      <path
        d="M15.8016 39.9251L15.8014 39.9249C15.527 39.6833 11.9212 36.4691 8.39218 31.8285C4.83574 27.1518 1.5 21.2099 1.5 15.486C1.5 7.4993 8.00568 1 16 1C23.9943 1 30.5 7.4993 30.5 15.486C30.5 21.2099 27.1643 27.1518 23.6078 31.8287C20.0899 36.4549 16.4956 39.664 16.2 39.9253C16.142 39.9755 16.0718 40 16 40C15.9281 40 15.8588 39.9754 15.8016 39.9251ZM6.38889 15.375C6.38889 20.6448 10.7072 24.9167 16 24.9167C21.2928 24.9167 25.6111 20.6448 25.6111 15.375C25.6111 10.1052 21.2928 5.83333 16 5.83333C10.7072 5.83333 6.38889 10.1052 6.38889 15.375Z"
        stroke="#C4C4C4"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Other;
