import React from 'react';

function Munich(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="#4c6c21"
        d="M107.3 244.15c-.76-12.34-5.91-22.79-11.26-22.79-5.44 0-10.49 10.29-11.27 22.93v40.47h22.53v-40.61zm-19.73 37.82v-37.59c.8-13.1 6.02-20.22 8.48-20.22s7.67 7.09 8.47 20.08v37.74H87.57v-.01zM139.8 244.15c-.76-12.34-5.91-22.79-11.26-22.79-5.44 0-10.49 10.29-11.27 22.93v40.47h22.53v-40.61zm-19.74 37.82v-37.59c.8-13.1 6.02-20.22 8.48-20.22 2.46 0 7.67 7.09 8.47 20.08v37.74h-16.95v-.01zM172.29 244.15c-.76-12.34-5.91-22.79-11.26-22.79-5.44 0-10.49 10.29-11.27 22.93v40.47h22.53v-40.61zm-19.73 37.82v-37.59c.8-13.1 6.02-20.22 8.48-20.22s7.67 7.09 8.47 20.08v37.74h-16.95v-.01zM204.78 244.15c-.76-12.34-5.91-22.79-11.26-22.79-5.44 0-10.49 10.29-11.27 22.93v40.47h22.53v-40.61zm-19.73 37.82v-37.59c.8-13.1 6.02-20.22 8.48-20.22s7.67 7.09 8.47 20.08v37.74h-16.95v-.01zM237.28 244.15c-.76-12.34-5.91-22.79-11.26-22.79-5.44 0-10.49 10.29-11.27 22.93v40.47h22.53v-40.61zm-19.74 37.82v-37.59c.8-13.1 6.02-20.22 8.48-20.22s7.67 7.09 8.47 20.08v37.74h-16.95v-.01zM269.77 244.15c-.76-12.34-5.91-22.79-11.26-22.79-5.44 0-10.49 10.29-11.27 22.93v40.47h22.53v-40.61zm-19.73 37.82v-37.59c.8-13.1 6.02-20.22 8.48-20.22s7.67 7.09 8.47 20.08v37.74h-16.95v-.01z"
      />
      <path
        fill="#4c6c21"
        d="M284.46 288.92H15.54V145.28h231.51l23.91 37.92-.16 10.81 4.53.08 9.12 13.53v81.3h.01zm-262.59-6.33h256.27v-73.04l-6.2-9.2-7.55-.13.22-15.24-21.04-33.38H21.87v130.99z"
      />
      <path
        fill="#4c6c21"
        d="M16.45 149.87c-.3-1.68-.59-3.36-.89-5.04 1.84-3.81 3.68-7.62 5.52-11.44V67.7c-3.59-3.57-5.56-7.96-5.56-12.45 0-11.39 12.38-20.66 27.59-20.66S70.7 43.86 70.7 55.25c0 4.3-1.74 8.39-5.03 11.89v66.4l6.89 14.81c-18.7.51-37.4 1.02-56.11 1.52zM43.11 38.39c-13.12 0-23.8 7.57-23.8 16.86 0 3.72 1.71 7.26 4.95 10.24l.61.56v68.22l-5.17 10.39 46.91-.11-4.74-10.19v-68.8l.56-.56c2.93-2.89 4.47-6.27 4.47-9.77.01-9.27-10.66-16.84-23.79-16.84zM43.11 33.25c-1.4 0-2.53-1.13-2.53-2.53V16.61c0-1.4 1.13-2.53 2.53-2.53s2.53 1.13 2.53 2.53v14.11c0 1.4-1.13 2.53-2.53 2.53z"
      />
      <circle fill="#4c6c21" cx="43.11" cy="32.11" r="5.01" />
      <path
        fill="#4c6c21"
        d="M22.65 65h40.93v3.79H22.65zM22.65 75.67h40.93v3.79H22.65zM22.65 105.46h40.93v3.79H22.65zM22.65 132.68h40.93v3.79H22.65zM43.11 129.96c-4.91 0-8.9-3.99-8.9-8.9s3.99-8.9 8.9-8.9 8.9 3.99 8.9 8.9-3.99 8.9-8.9 8.9zm0-15.27c-3.51 0-6.37 2.86-6.37 6.37s2.86 6.37 6.37 6.37 6.37-2.86 6.37-6.37-2.85-6.37-6.37-6.37z"
      />
      <path
        fill="#4c6c21"
        d="M53.47 107.39H32.75c-.7 0-1.26-.57-1.26-1.26V91.28c0-.51.31-.97.78-1.17L42.8 85.7c.32-.13.68-.13.99 0l10.19 4.41c.46.2.76.66.76 1.16v14.84c0 .71-.57 1.28-1.27 1.28zm-19.46-2.53h18.2V92.11l-8.94-3.87-9.26 3.88v12.74z"
      />
      <path
        fill="none"
        stroke="#4c6c21"
        strokeMiterlimit="10"
        d="M83.1 134.57h40.93"
      />
      <path
        fill="#4c6c21"
        stroke="#4c6c21"
        strokeMiterlimit="10"
        d="M83.1 132.68h40.93v3.79H83.1z"
      />
      <path
        fill="#4c6c21"
        d="M72.76 148.49l7.51-15.09V67.71c-3.59-3.57-5.56-7.96-5.56-12.45 0-11.39 12.38-20.66 27.59-20.66s27.59 9.27 27.59 20.66c0 4.3-1.74 8.39-5.03 11.89v66.4l6.89 14.81-58.99.13zm29.54-110.1c-13.12 0-23.8 7.57-23.8 16.86 0 3.72 1.71 7.26 4.95 10.24l.61.56v68.22l-5.17 10.39 46.91-.11-4.74-10.19v-68.8l.56-.56c2.93-2.89 4.47-6.27 4.47-9.77.01-9.27-10.66-16.84-23.79-16.84zM102.3 33.25c-1.4 0-2.53-1.13-2.53-2.53V16.61c0-1.4 1.13-2.53 2.53-2.53s2.53 1.13 2.53 2.53v14.11c0 1.4-1.13 2.53-2.53 2.53z"
      />
      <circle fill="#4c6c21" cx="102.3" cy="32.11" r="5.01" />
      <path
        fill="#4c6c21"
        d="M81.84 65h40.93v3.79H81.84zM81.84 75.67h40.93v3.79H81.84zM81.84 105.46h40.93v3.79H81.84z"
      />
      <g>
        <path
          className="prefix__st0"
          d="M112.67 107.39H91.94c-.7 0-1.26-.57-1.26-1.26V91.28c0-.51.31-.97.78-1.17l10.53-4.41c.32-.13.68-.13.99.01l10.19 4.41c.46.2.76.66.76 1.16v14.84c0 .7-.57 1.27-1.26 1.27zm-19.47-2.53h18.2V92.11l-8.94-3.87-9.26 3.88v12.74z"
        />
      </g>
      <g>
        <path
          fill="#4c6c21"
          stroke="#4c6c21"
          strokeMiterlimit="10"
          d="M71.17 151.77h5.06V285.7h-5.06z"
        />
      </g>
      <g>
        <path
          fill="#4c6c21"
          stroke="#4c6c21"
          strokeMiterlimit="10"
          d="M33.07 151.3h2.53v133.93h-2.53z"
        />
      </g>
      <g>
        <path
          fill="#4c6c21"
          stroke="#4c6c21"
          strokeMiterlimit="10"
          d="M57.26 151.77h2.53V285.7h-2.53z"
        />
      </g>
      <g>
        <path
          fill="#4c6c21"
          stroke="#4c6c21"
          strokeMiterlimit="10"
          d="M74.59 182.83h193.19v2.53H74.59z"
        />
      </g>
      <g>
        <path
          fill="#4c6c21"
          stroke="#4c6c21"
          strokeMiterlimit="10"
          d="M73.53 196.57h195.96v3.79H73.53z"
        />
      </g>
      <path
        stroke="#4c6c21"
        strokeMiterlimit="10"
        fill="none"
        strokeWidth="2"
        d="M15.85 171.74h57.08M15.85 190.43h57.08M15.85 209.12h57.08M15.85 227.81h57.08M15.85 246.5h57.08M15.85 265.19h57.08"
      />
    </svg>
  );
}

export default Munich;
