import React from 'react';

function Franfurt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0"
      y="0"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="none"
        stroke="#4c6c21"
        strokeWidth="5"
        strokeMiterlimit="10"
        d="M6.04 269.04h285.61M6.04 277.85h285.61"
      />
      <path
        d="M67.8 268.03c13.2-9.62 46.49-31.14 94.13-33.72 59.08-3.21 101.01 24.84 113.27 33.72"
        fill="none"
        stroke="#4c6c21"
        strokeWidth="5"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        fill="none"
        stroke="#4c6c21"
        strokeMiterlimit="10"
        strokeWidth="5"
        d="M165.17 278.92v-44.09M179.17 278.92v-44.09"
      />
      <path
        fill="none"
        stroke="#4c6c21"
        strokeWidth="3"
        strokeMiterlimit="10"
        d="M110.92 234.04V79.05h14.51v24.36h7.78v11.4h5.7v12.44h6.22v-10.37h12.96v33.17h5.7v17.11h10.37v11.4h7.78v42.51M191.26 219v-74.12h9.33v-12.96h7.78v12.44h11.4v8.29h9.85v79.83M239.47 232.48v-22.81h4.15v-30.06h6.22v-30.07h6.73v30.59h7.78v27.99h5.7v38.36M73.38 245.18V74.28h9.52V42.69h10.66V24.8h12.94v209.25M62.41 261.71v-95.8h-5.59v-18.43H44.31v-87.9h-9.88v84.14H17.64v12.82h-6.91v105.17"
      />
      <path
        fill="#4c6c21"
        d="M15.18 162.04h7.57v7.57h-7.57zM15.18 171.46h7.57v7.57h-7.57zM25.07 171.46h7.57v17.01h-7.57zM25.07 162.04h7.57v7.57h-7.57zM35.43 162.04H43v7.57h-7.57zM35.43 171.46H43v7.57h-7.57zM80.74 79.05h3.79v121.83h-3.79zM196.04 159.43h27.83v5.11h-27.83zM196.04 167.69h27.83v5.11h-27.83zM196.04 175.96h27.83v5.11h-27.83z"
      />
    </svg>
  );
}

export default Franfurt;
