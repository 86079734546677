import * as yup from 'yup';

if (typeof window !== 'undefined') {
  yup.setLocale({
    mixed: {
      required: 'common:validations.required',
    },
    string: {
      email: 'common:validations.email',
    },
    array: {
      min: () => 'common:validations.required',
    },
  });
}

export default yup;
