import { AnySchema, InferType } from 'yup';

import { Caterer } from 'lib/algolia/model';
import { Answers } from 'qualification/context/model';

export type QuestionContext = {
  answers: Answers;
  selectedCaterer?: Caterer;
  isManagedAccount?: boolean;
};

export type QuestionBase<ID> = {
  id: ID;
  content?: {
    titleEn: string;
    titleDe: string;
    subtitleEn?: string;
    subtitleDe?: string;
    assistiveTextEn?: string;
    assistiveTextDe?: string;
    mobileSubtitleEn?: string;
    mobileSubtitleDe?: string;
    placeholderDe?: string;
    placeholderEn?: string;
    image?: string;
    footNote?: string;
  };
  condition?: (isLoggedIn: boolean) => boolean;
};

type ValidationData<T extends AnySchema> = {
  getValidator: (context: QuestionContext) => T;
};

export type ImageComponent = React.FC<
  { height?: string | number; width?: string | number; alt?: string } & {
    [key: string]: any;
  }
>;

type Item<T> = {
  labelEn: string;
  labelDe: string;
  value: T;
  imageComponent?: ImageComponent;
  image?: string;
};

type SimpleQuestion<ID, T extends AnySchema> = QuestionBase<ID> &
  ValidationData<T> & {
    getDefaultValue?: (context: QuestionContext) => InferType<T>;
    suggestions?: Item<InferType<T>>[];
  };

type NonArrayType<T> = T extends Array<infer U> ? U : T;

type SelectorQuestion<ID, T extends AnySchema> = QuestionBase<ID> &
  ValidationData<T> & {
    getDefaultValue?: (context: QuestionContext) => InferType<T>;
    getItems: (context: QuestionContext) => Item<NonArrayType<InferType<T>>>[];
    attributes?: {
      multi: boolean;
    };
  };

type DateQuestion<ID, T extends AnySchema> = QuestionBase<ID> &
  ValidationData<T> & {
    getAttributes: (
      context: QuestionContext
    ) => {
      minDate?: {
        leadTimeDays: number;
        messageEn: string;
        messageDe: string;
      };
    };
  };

export function createSelectorQuestion<ID extends string, T extends AnySchema>(
  question: SelectorQuestion<ID, T>
): SelectorQuestion<ID, T> {
  return question;
}

export function createDateQuestion<ID extends string, T extends AnySchema>(
  question: DateQuestion<ID, T>
): DateQuestion<ID, T> {
  return question;
}

export function createStep<ID extends string, T>(
  options: QuestionBase<ID> & { questions: T }
) {
  return options;
}

export function createQuestion<ID extends string, T extends AnySchema>(
  options: SimpleQuestion<ID, T>
) {
  return options;
}
